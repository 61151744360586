
import EventCard from '@/components/cards/EventCard.vue'
import EventFilter from 'tradingmate-modules/src/filters/EventFilter'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import { EventModel, EventPostStatus } from 'tradingmate-modules/src/models/api/events'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import { Cards } from '@/components/cards'
import Paging from '@/components/actions/Paging.vue'

@Component({
  components: {
    Cards,
    EventCard,
    Paging
  }
})
export default class EventIndex extends Vue {
  private eventData: PagedResultSet<EventModel> | null = null
  private isLoading = false

  mounted () {
    this.isLoading = true
    Services.API.Events.GetEvents(
      new EventFilter({
        BusinessBranchId: this.$store.state.identity.BusinessBranch.BusinessBranchId,
        Status: EventPostStatus.None
      })
    ).then(events => {
      this.eventData = events
    }).finally(() => { this.isLoading = false })
  }

  newEventPost () {
    this.$router.push('/events/new')
  }
}
